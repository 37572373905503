import {axiosApi} from "../../../axios-api";
import {message} from "antd";
export const runProject = async (projectId, outputs = [], rerun = false) => {
    const body = { full_rerun: rerun }
    outputs.length > 0 && (body['outputs'] = outputs);

    try {
        await axiosApi.patch(`v2/projects/${projectId}/generation/`, body);
        return true;
    } catch (error) {
        if (error.isAxiosError) {
          message.error(error.response.data?.detail || 'Run project finished with error!');
        } else {
            throw new Error(error);
        }
	      return false;
    }
};

export const stopProject = async (id, outputs=null) => {
	try {
		await  axiosApi.patch(`v2/projects/${id}/stop/`,outputs )
		message.success('Cancellation in progress');
	} catch (error) {
		if (error.response.status === 400) {
			message.error(error.response.data?.message || 'Feed stopping finished with error!');
		} else {
			message.error('Something went wrong during the project cancellation');
		}
	}
};

export const getOutputImages = async (id, page) => {
    try {
        const {data} = await axiosApi.get(`/preview/${id}?page_num=${page}`);
        return data;
    } catch (error) {
        if (error.isAxiosError) {
            return message.warning({
                content: error.message,
                duration: 3,
            });
        }
        throw new Error(error);
    }
};


export const updateProject = async (projectId, requestBody) => {
    try {
       const {data} = await axiosApi.patch(`v2/projects/${projectId}/`, requestBody);
       return data;
    } catch (error) {
        if (error.isAxiosError) {
          const {data} = error.response;
          let errorField = '';
          Object.keys(data).forEach(key => {
            errorField = data[key][0];
          });
            message.warning({
                content: errorField || error.message,
                duration: 3,
            });
            return;
        }
        throw new Error(error);
    }
};

export const deleteProject = async (projectId, projectName) => {
    try {
        await axiosApi.delete(`v2/projects/${projectId}/`);
        message.success({
            content: `Project "${projectName}" has been deleted!`,
            duration: 3
        });

    } catch (error) {
        if (error.isAxiosError) {
            return message.warning({
                content: error.message,
                duration: 3,
            });
        }
        throw new Error(error);
    }
};

export const createNewProject = async (requestBody) => {
    try {
        await axiosApi.post('v2/projects/', requestBody);
        message.success('Project successfully created');

    } catch (error) {
        if (error.isAxiosError) {
          if (error.response?.data?.organization) {
            message.error({
              content: error.response.data.organization.join(''),
              duration: 3,
            });
            throw new Error(error);
          }

          message.warning({
            content: `Something went wrong! ${error.message}`,
            duration: 3,
          });
        } else {
            throw new Error(error);
        }
    }
};

export const getOutputDetails = async (id, setLoading) => {
  try {
    setLoading(true);
    const {data} = await axiosApi.get(`/v2/outputs/${id}/`);
    return data;
  }catch (error) {
    if (error.isAxiosError) {
      message.error(`Getting output's details finished with error `);
    } else {
      throw new Error(error);
    }
  } finally {
    setLoading(false);
  }
};

export const getOutputLogs = async(id, setLoading) => {
  try {
    setLoading(true);
    const {data} = await axiosApi.get(`/v2/outputs/logs/${id}/`);
    return data;
  }catch (error) {
    if (error.isAxiosError) {
      if (error.response.data.detail === 'Not found.') {
        return {message: 'No output validation logs'};
      } else {
        return {error: 'Fetching of output validation logs finished with error, please try again in a while'};
      }

    } else {
      throw new Error(error);
    }
  } finally {
    setLoading(false);
  }
};
