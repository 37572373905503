import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import convert from 'color-convert';

import styles from './ColorEditor.module.css';

export default function (props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('');

  const { element } = props;

  useEffect(() => {
    if (element || props.value) {
      setColor(element ? element.getAttr('fill') : props.value);
    } else {
      setColor('');
    }
  }, [element, props.value]);

  const showColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const changeColor = (selectedColor) => {
    const { r, g, b, a } = selectedColor.rgb;
    const color = `rgba(${r}, ${g}, ${b}, ${a})`;

    if (element) {
      element.setAttr('fill', color);
    }

    setColor(color);

    if (props.onChange) {
      props.onChange(color);
    }

    propertyChanged();
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };
  const covertColor = (givenColor) => {
    if (givenColor.startsWith('#')) return givenColor;

    let convertedColor;
    if (givenColor.startsWith('rgb')) {
      const rgbaValues = givenColor
        .substring(givenColor.indexOf('(') + 1, givenColor.lastIndexOf(')'))
        .split(',');
      convertedColor = convert.rgb.hex(rgbaValues);
    } else {
      convertedColor = convert.keyword.hex(givenColor);
    }
    return `#${convertedColor}`;
  };

  return (
    <div className={styles.colorEditor}>
      <div
        className={styles.wrapper}
        onClick={showColorPicker}
        role='presentation'
      >
        <div className={styles.boxBlock}>
          <div className={styles.boxItem} style={{ background: color || '' }} />
        </div>
        <span className={styles.title}>
          {color ? covertColor(color) : 'Not selected'}
        </span>
      </div>
      {displayColorPicker && (
        <div className={styles.colorPicker}>
          <div
            className={styles.cover}
            onClick={handleClose}
            role='presentation'
          />
          <SketchPicker color={color} onChange={changeColor} />
        </div>
      )}
    </div>
  );
}
