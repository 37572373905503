import {Button, Empty, Spin, Tooltip} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../svg/icon/arrow.svg';
import EditableOrganizationName from '../../EditableOrganizationName/EditableOrganizationName';
import ProjectBlock from '../ProjectBlock/ProjectBlock';
import { createNewProject, deleteProject } from '../ProjectsApi/projectsApi';
import { SearchInput } from 'components/shared/SearchInput/SearchInput';

import styles from './ProjectsPage.module.css';
import {getUserLocalStorage} from '../../../helpers';

function ProjectsPage({
  getOrganizations,
  selectedProjectsList,
  getProjectsByOrganization,
  organizationsWithFilteredProjects,
  getOneProjectByOrganization,
  updateOrganizationName,
  totalProjects,
  isAddingNewProject,
  onAddingProjectFinish,
  loading,
  updateSelectedProjectsList

}) {
  const { organizationId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const [organizationUpdating, setOrganizationUpdating] = useState(false);
  const [changingOrganization, setChangingOrganization] = useState(null);
  const [isAddingNewProjectByOrganization, setIsAddingNewProjectByOrganization] = useState(null);
	const userInfo = getUserLocalStorage('userInfo');
  const organizationsWithAvailableProjects = organizationsWithFilteredProjects.filter((organization) => organization.projects_filtered_by_id.length);

	useEffect(() => {
    if (organizationId) {
      getProjectsByOrganization(organizationId);
    } else {
      getProjectsByOrganization();
    }
  }, [organizationId]);


  const onProjectDelete = async (projectId, projectName) => {
    await deleteProject(projectId, projectName);
    await getProjectsByOrganization(organizationId);
    await getOrganizations();
  };

  const submitProjectCreation = async (requestBody) => {
    try {
      await createNewProject(requestBody);
    } catch (e) {
      return;
    }

    await onAddingProjectFinish();
    await getProjectsByOrganization(organizationId);
    await getOrganizations();
    setIsAddingNewProjectByOrganization(null);
  };

  const cancelProjectCreation = () => {
    if (organizationId) {
      onAddingProjectFinish()
    } else {
      setIsAddingNewProjectByOrganization(null);
    }
  };

  const submitOrganizationNameChange = async (id, name) => {
    setChangingOrganization(id);
    await updateOrganizationName(id, name, setOrganizationUpdating);
  };

  const onAddProjectClick = (id) => {
    if (id === isAddingNewProjectByOrganization) {
      setIsAddingNewProjectByOrganization(null)
    } else {
      setIsAddingNewProjectByOrganization(id);
    }
  };

  const isAddProjectButtonDisabled = (organization) => {
    return organization.role !== 1 || (organization.notice_code && organization.notice_code !== 5) || isAddingNewProjectByOrganization === organization.id || (isAddingNewProjectByOrganization && organization.id !== isAddingNewProjectByOrganization);
  };

  const getAddProjectTooltip = (organization) => {
    const editingOrganization = selectedProjectsList.find(project => project.id === isAddingNewProjectByOrganization);

    switch (true) {
      case organization.role !== 1:
        return 'Only Administrator can add new project';
      case organization.id === isAddingNewProjectByOrganization:
        return 'Finish or cancel adding project to add another one';
      case editingOrganization && isAddingNewProjectByOrganization && organization.id !== isAddingNewProjectByOrganization:
        return `Process of adding project in organization "${editingOrganization.name}" hasn't finished`;
      default:
        return '';
    }
  };


  return (
    <div className='custom-container'>
      { loading && selectedProjectsList.length === 0 ? (
        <div className='centered-container'>
          <Spin size='large' fullscreen />
        </div>
      ) : (
        <div>
          <SearchInput
            className={styles.searchInput}
            entity='projects'
            entityHelper={organizationsWithFilteredProjects}
            setEntities={updateSelectedProjectsList}
            onReset={() => updateSelectedProjectsList(organizationId ? organizationsWithFilteredProjects : organizationsWithAvailableProjects)}
          />
          <div className={styles.organizationsOfProjectsBlock}>
            {!!selectedProjectsList.length &&
              selectedProjectsList.map((organization) => (
                 <div
                  key={organization.id}
                  className={styles.organizationOfProject}
                >
                  <div className={styles.topBlock}>
                    {pathname !== '/projects-new' && (
                      <Button
                        onClick={() => history.push('/admin')}
                        className={styles.backButton}
                        type='text'
                        shape='circle'
                        icon={<BackArrow />}
                      />
                    )}
                    <EditableOrganizationName
                      value={organization.name}
                      disabled={!organization.generation_allowed}
                      onSubmit={(value) =>
                        submitOrganizationNameChange(
                          organization.id,
                          value.organizationName,
                        )
                      }
                      nameLoading={
                        changingOrganization === organization.id &&
                        organizationUpdating
                      }
                    />
                    {pathname === '/projects-new' && (
                        <Tooltip title={getAddProjectTooltip(organization)}>
                          <Button
                            onClick={() => onAddProjectClick(organization.id)}
                            disabled={isAddProjectButtonDisabled(organization)}
                            className={styles.addProjectButton}
                          >
                            Add project
                          </Button>
                        </Tooltip>
                    )}
                  </div>
                  <div className={styles.projectsBlock}>
                    {(isAddingNewProject || isAddingNewProjectByOrganization === organization.id) &&
                        (!organization.notice_code || organization.notice_code === 5)  && (
                      <ProjectBlock
                        organization={organization}
                        onAddingProjectFinish={submitProjectCreation}
                        cancelProjectCreating={cancelProjectCreation}
                        isSuperAdmin={userInfo.isSuperUser}
                        isCreating
                      />
                    )}
                    {organization.projects_filtered_by_id.length > 0 &&
                      organization.projects_filtered_by_id.map((project) => (
                        <ProjectBlock
                          key={project.id}
                          organization={organization}
                          project={project}
                          isAllowed={organization.generation_allowed}
                          role={organization.role}
                          onProjectDelete={onProjectDelete}
                          getOneProjectByOrganization={
                            getOneProjectByOrganization
                          }
                          isSuperAdmin={userInfo.isSuperUser}
                          organizationId={organizationId || null}
                        />
                      ))}
                    {organization.projects_filtered_by_id.length === 0 &&
                      !isAddingNewProject &&
                      !loading && (
                        <Empty
                          className='centered-container'
                          description={
                            organizationId &&
                            totalProjects.length &&
                            totalProjects[0].projects_filtered_by_id.length ===
                              0
                              ? 'There is no projects to select'
                              : 'No project has been selected'
                          }
                        />
                      )}
                  </div>
                </div>
              ))}
            {!loading && selectedProjectsList.length === 0 && (
              <Empty
                className='centered-container'
                description={
                  organizationId &&
                  totalProjects.length &&
                  totalProjects[0].projects_filtered_by_id.length === 0
                    ? 'There is no projects to select'
                    : 'No project has been selected'
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectsPage;
